import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Filter } from '../../models/export/filter.model';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  private apiUrl = environment.api_url;

  constructor(private http: HttpClient) {}

  downloadAndSaveFile(
    endpoint: string,
    itemId: number,
    filters?: Filter[]
  ): void {
    this.downloadItemsSummary(itemId, endpoint, filters).subscribe(
      ({ blob, filename }) => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(objectUrl);
      }
    );
  }

  downloadItemsSummary(
    itemId: number,
    endpoint: string,
    filters?: Filter[]
  ): Observable<{ blob: Blob; filename: string }> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      accept: 'application/json',
    });
    let params = new HttpParams();
    if (itemId) {
      params = params.set('itemId', itemId);
    }

    if (filters && Array.isArray(filters)) {
      filters.forEach(filter => {
        if (filter.field && filter.value) {
          params = params.set(filter.field, filter.value.toString());
        }
      });
    }

    return this.http
      .get(this.apiUrl + endpoint, {
        headers: headers,
        params: params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          const contentDisposition = (response.headers as HttpHeaders).get(
            'content-disposition'
          );
          let filename = 'téléchargement.xslx';

          if (contentDisposition) {
            const matches = /filename="?([^"]+)"?/.exec(contentDisposition);
            if (matches != null && matches[1]) {
              filename = matches[1];
            }
          }
          return { blob: response.body as Blob, filename: filename };
        })
      );
  }
}
