export const environment = {
  production: true,
  api_url: `${window.location.origin}/api/bff`,
  enabledModule: ['meal-tray', 'compensation', 'settings'],
  numberOfFutureDaysToSelectAPeqOrderDate: 7,
  numberOfPreviousMonthsToSelectAPeqOrderDate: 3,
  okta: {
    issuer: 'https://dev-99778458.okta.com/oauth2/default',
    clientId: '0oaik3fyxoRuUC78a5d7',
    redirectUri: `${window.location.origin}/callback`,
  },
  debug: {
    enable_date_out_of_range: true,
    enable_send_order_from_peqorder_if_before_current_date: true,
    enable_send_unit_order_from_peqorder_if_not_current_date: false,
    enable_refresh_order_from_peqorder_if_before_current_date: true,
    enable_refresh_order_from_peqorder_if_same_current_date: true,
  },
};
