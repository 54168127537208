import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

export interface FormField {
  type: 'text' | 'date' | 'list' | 'chips' | 'icon';
  name: string;
  value?: any;
  label?: string;
  options?: string[];
  validators?: Validators[];
  disabled?: boolean;
  ngClass?: string;
  iconType?: string;
}

export interface FormConfig {
  fieldsDefinition: FormField[];
  crossValidation?: ValidatorFn[];
}

@Component({
  selector: 'logpn-form',
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss',
})
export class FormComponent {
  @Output() save = new EventEmitter<FormGroup>();
  @Output() cancel = new EventEmitter<void>();
  @Input() set formConfig(formConfig: FormConfig) {
    this.initForm(formConfig);
  }

  fieldsDefinition!: FormField[];
  form!: FormGroup;

  constructor(private fb: FormBuilder) {}

  initForm(formConfig: FormConfig) {
    this.fieldsDefinition = formConfig.fieldsDefinition;

    const group: { [key: string]: any } = {};
    this.fieldsDefinition.forEach(field => {
      group[field.name] = [
        { value: field.value, disabled: field.disabled },
        field.validators,
      ];
    });
    this.form = this.fb.group(group);

    if (formConfig.crossValidation) {
      this.form.addValidators(formConfig.crossValidation);
    }
  }

  onCancel() {
    this.cancel.emit();
  }

  onSubmit() {
    if (this.form.valid) {
      this.save.emit(this.form);
    }
  }
}
