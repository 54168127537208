import {
  AfterViewInit,
  Component,
  forwardRef,
  Injector,
  Input,
  LOCALE_ID,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgControl,
  Validators,
} from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DatePipe } from '@angular/common';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'logpn-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDateComponent),
      multi: true,
    },
    DatePipe,
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' }, // Set calendar locale to French
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ],
})
export class InputDateComponent implements ControlValueAccessor, AfterViewInit {
  @Input() label?: string = '';

  private _value: any;

  control!: FormControl;
  required: boolean = false;

  constructor(
    private readonly datePipe: DatePipe,
    private injector: Injector
  ) {}

  ngAfterViewInit(): void {
    const ngControl = this.injector.get(NgControl, null);
    if (ngControl) {
      setTimeout(() => {
        this.control = Object.assign(
          new FormControl(),
          ngControl.control
        ) as FormControl;

        if (this._value) {
          this.control.setValue(this._value);
        }

        this.control.valueChanges.subscribe(value => {
          value = this.datePipe.transform(value, 'yyyy-MM-dd');
          if (value != this._value) {
            this._value = value;
            this.onChange(value);
          }
        });

        this.required = this.control?.hasValidator(Validators.required);
      });
    }
  }

  onChange: any = () => {};

  onTouched = () => {};

  writeValue(value: any): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
