import { booleanAttribute, Component, Input } from '@angular/core';
import { Filter } from '../../../models/export/filter.model';
import { ExportService } from '../../services/export.service';

@Component({
  selector: 'app-export-download-button',
  templateUrl: './download-button.component.html',
  styleUrl: './download-button.component.scss',
})
export class DownloadButtonComponent {
  @Input({ transform: booleanAttribute }) buttonDisable: boolean = false;
  @Input() itemId?: number;
  @Input() endpointExcel?: string;
  @Input() filters?: Filter[];

  constructor(private readonly exportService: ExportService) {}

  downloadItemsSummary(): void {
    this.itemId !== undefined &&
      this.endpointExcel !== undefined &&
      this.exportService.downloadAndSaveFile(
        this.endpointExcel,
        this.itemId,
        this.filters
      );
  }
}
