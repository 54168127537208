<div class="flex-column">
  <label for="input" [ngClass]="{ required: required }">
    {{ label | transloco }}
  </label>

  <mat-form-field appearance="outline">
    <mat-select id="input" [formControl]="control">
      <mat-option *ngFor="let option of options" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
