<table mat-table [dataSource]="dataSource" matSort>
  @for (column of columnsDefinition; track column.name) {
    <ng-container [matColumnDef]="column.name">
      @if (column.sortable) {
        <th
          *matHeaderCellDef
          mat-header-cell
          mat-sort-header
          [id]="column.name"
          scope="col"
          class="title-table {{ column.headerStyle ?? '' }}">
          {{ column.header | transloco }}
        </th>
      } @else {
        <th
          *matHeaderCellDef
          mat-header-cell
          [id]="column.name"
          scope="col"
          class="title-table {{ column.headerStyle ?? '' }}">
          {{ column.header | transloco }}
        </th>
      }
      <ng-container *matCellDef="let element">
        <td
          [headers]="column.name"
          class="table-cell-content content-table"
          mat-cell>
          @if (column.isCellCustomized === true) {
            <ng-container
              *ngIf="customCellsTemplateRef"
              [ngTemplateOutlet]="customCellsTemplateRef"
              [ngTemplateOutletContext]="{
                $implicit: element,
                columnDefinition: column,
              }">
            </ng-container>
          } @else {
            @switch (column.cellType) {
              @case ('innerHTML') {
                <div class="justify-space-between">
                  <div [innerHTML]="element[column.name]"></div>
                </div>
              }
              @case ('hours') {
                {{ element[column.name] | date: 'HH:mm' }}
              }
              @case ('date') {
                {{ element[column.name] | date: 'dd/MM/yyyy' }}
              }
              @case ('date-full') {
                {{ element[column.name] | date: "dd MMMM HH'h'mm" }}
              }
              @default {
                {{ element[column.name] }}
              }
            }
          }
        </td>
      </ng-container>
    </ng-container>
  }

  <ng-container matColumnDef="actions">
    <th
      id="actions"
      scope="col"
      mat-header-cell
      *matHeaderCellDef
      class="action-table"></th>
    <td headers="actions" mat-cell *matCellDef="let element">
      <div class="actions justify-end">
        <ng-container
          *ngIf="actionsTemplateRef"
          [ngTemplateOutlet]="actionsTemplateRef"
          [ngTemplateOutletContext]="{ $implicit: element }">
        </ng-container>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="footer">
    <th
      mat-footer-cell
      *matFooterCellDef
      [attr.colspan]="displayedColumns.length"
      class="table-footer">
      <mat-paginator
        [pageSizeOptions]="[20, 50, 100, 200]"
        showFirstLastButtons
        [attr.aria-label]="
          'mealTray.tableOrders.selectPagePeriodicElements' | transloco
        ">
      </mat-paginator>
      <div class="table-footer-content content-table">
        <ng-container *ngTemplateOutlet="footerTemplateRef"></ng-container>
      </div>
    </th>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr
    mat-row
    *matRowDef="let row; let i = index; columns: displayedColumns"
    class="tr-mat-row"
    [ngClass]="{
      focus: selectedRow ? row.id == selectedRow.id : false,
      'clickable-row': isClickableRow,
    }"
    [id]="'row-' + i"
    (click)="emitRowData(i)"
    (keydown)="onKeyDownHandler($event, i)"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" [attr.colspan]="displayedColumns.length">
      @if (!(loading$ | async) || (loading$ | async) === false) {
        <logpn-no-result class="mg-sm"></logpn-no-result>
      } @else {
        <div class="mg-sm">&nbsp;</div>
      }
    </td>
  </tr>

  @if (displayPaginator) {
    <tr mat-footer-row *matFooterRowDef="['footer']; sticky: true"></tr>
  }
</table>
