import {
  AfterViewInit,
  Component,
  forwardRef,
  Injector,
  Input,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgControl,
  Validators,
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'logpn-input-chip',
  templateUrl: './input-chip.component.html',
  styleUrls: ['./input-chip.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputChipComponent),
      multi: true,
    },
  ],
})
export class InputChipComponent implements ControlValueAccessor, AfterViewInit {
  @Input() label?: string = '';

  private _value: any;

  control!: FormControl;
  required: boolean = false;

  constructor(private injector: Injector) {}

  ngAfterViewInit(): void {
    const ngControl = this.injector.get(NgControl, null);
    if (ngControl) {
      setTimeout(() => {
        this.control = Object.assign(
          new FormControl(),
          ngControl.control
        ) as FormControl;

        if (this._value) {
          this.control.setValue(this._value);
        }

        this.control.valueChanges.subscribe(value => {
          if (value != this._value) {
            this._value = value;
            this.onChange(value);
          }
        });

        this.required = this.control?.hasValidator(Validators.required);
      });
    }
  }

  onChange: any = () => {};

  onTouched = () => {};

  writeValue(value: any): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  removeReactiveKeyword(value: string) {
    const values = this.control.value.filter(
      (element: string) => element != value
    );
    this.control.setValue(values);
  }

  addValue(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.control.value.push(value);
    }
    event.chipInput!.clear();
  }
}
