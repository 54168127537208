<div class="flex-column">
    <label for="input" [ngClass]="{required:required}">
        {{ label | transloco }}
    </label>

    <mat-form-field appearance="outline">
        <input matInput
               id="input"
               placeholder="DD/MM/YY"
               [formControl]="control"
               [matDatepicker]="picker"
        />
        <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</div>