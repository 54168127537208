<div class="flex-column">
  <label for="input" [ngClass]="{ required: required }">
    {{ label | transloco }}
  </label>

  <mat-form-field appearance="outline">
    <textarea
      id="input"
      maxlength="300"
      #comment
      class="input-area"
      matInput
      type="text"
      [formControl]="control"
      cdkTextareaAutosize></textarea>
    <button matSuffix mat-icon-button aria-label="Clear">
      <mat-icon>close</mat-icon>
    </button>
    <mat-hint align="start">{{ comment.value.length }} / 300</mat-hint>
  </mat-form-field>
</div>
