import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessErrorComponent } from './shared/composants/errors/access-error/access-error.component';
import { moduleGuard } from './core/guard/module.guard';
import { Modules } from './shared/utils/logistique-pn';

const routes: Routes = [
  {
    path: '',
    redirectTo: `/home`,
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./modules/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'access-error',
    component: AccessErrorComponent,
  },
  {
    path: 'meal-tray',
    loadChildren: () =>
      import('./modules/meal-tray/meal-tray.module').then(
        m => m.MealTrayModule
      ),
    canActivate: [moduleGuard],
    data: { moduleName: Modules.MealTray },
  },
  {
    path: 'compensation',
    loadChildren: () =>
      import('./modules/compensation/compensation.module').then(
        m => m.CompensationModule
      ),
    canActivate: [moduleGuard],
    data: { moduleName: Modules.Compensation },
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./modules/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [moduleGuard],
    data: { moduleName: Modules.Settings },
  },
  {
    path: '**',
    redirectTo: `/home`,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
