<div class="flex-column">
    <label for="input" [ngClass]="{required:required}">
        {{ label | transloco }}
    </label>
    <mat-form-field appearance="outline">
    <input matInput
            id="input"
            type="text"
           [formControl]="control"
    />
    </mat-form-field>
</div>