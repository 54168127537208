import {
  AfterViewInit,
  Component,
  forwardRef,
  Injector,
  Input,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgControl,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'logpn-input-list',
  templateUrl: './input-list.component.html',
  styleUrls: ['./input-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputListComponent),
      multi: true,
    },
  ],
})
export class InputListComponent implements ControlValueAccessor, AfterViewInit {
  @Input() label?: string = '';
  @Input() options?: string[] = [];

  private _value: any;

  control!: FormControl;
  required: boolean = false;

  constructor(private injector: Injector) {}

  ngAfterViewInit(): void {
    const ngControl = this.injector.get(NgControl, null);
    if (ngControl) {
      setTimeout(() => {
        this.control = Object.assign(
          new FormControl(),
          ngControl.control
        ) as FormControl;

        if (this._value) {
          this.control.setValue(this._value);
        }

        this.control.valueChanges.subscribe(value => {
          if (value != this._value) {
            this._value = value;
            this.onChange(value);
          }
        });

        this.required = this.control?.hasValidator(Validators.required);
      });
    }
  }

  onChange: any = () => {};

  onTouched = () => {};

  writeValue(value: any): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
