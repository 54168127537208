import { NgModule } from '@angular/core';
import { IconComponent } from './composants/icon/icon.component';
import { HeaderComponent } from './composants/header/header.component';
import { SidenavComponent } from './composants/sidenav/sidenav.component';
import { LoaderComponent } from './composants/loader/loader.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from '../transloco-root.module';
import { IMPORTS_MATERIAL } from './material';
import { GenericModalComponent } from './composants/generic-modal/generic-modal.component';
import { NumberCellPipe } from './utils/pipes/number-cell.pipe';
import { TableComponent } from './composants/table/table.component';
import { CdkTrapFocus } from '@angular/cdk/a11y';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormatDateTrigramPipe } from './utils/pipes/format-date-trigram.pipe';
import { NoResultComponent } from './composants/no-result/no-result.component';
import { DownloadButtonComponent } from './composants/download-button/download-button.component';
import { TileComponent } from './composants/tile/tile.component';
import { TranslocoModule, TranslocoPipe } from '@jsverse/transloco';
import { FormComponent } from './composants/form/form.component';
import { InputTextComponent } from './composants/form/input/input-text/input-text.component';
import { InputListComponent } from './composants/form/input/input-list/input-list.component';
import { InputDateComponent } from './composants/form/input/input-date/input-date.component';
import { InputChipComponent } from './composants/form/input/input-chip/input-chip.component';
import { InputTextareaComponent } from './composants/form/input/input-textarea/input-textarea.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    TranslocoRootModule,
    FormsModule,
    CdkTrapFocus,
    ...IMPORTS_MATERIAL,
    ReactiveFormsModule,
  ],
  declarations: [
    HeaderComponent,
    SidenavComponent,
    LoaderComponent,
    IconComponent,
    GenericModalComponent,
    TableComponent,
    NoResultComponent,
    NumberCellPipe,
    FormatDateTrigramPipe,
    DownloadButtonComponent,
    TileComponent,
    FormComponent,
    InputChipComponent,
    InputDateComponent,
    InputListComponent,
    InputTextComponent,
    InputTextareaComponent,
  ],
  exports: [
    ...IMPORTS_MATERIAL,
    HeaderComponent,
    SidenavComponent,
    LoaderComponent,
    IconComponent,
    GenericModalComponent,
    TableComponent,
    NoResultComponent,
    NumberCellPipe,
    FormatDateTrigramPipe,
    DownloadButtonComponent,
    TileComponent,
    InputChipComponent,
    InputDateComponent,
    InputListComponent,
    InputTextComponent,
    InputTextareaComponent,
    TranslocoPipe,
    TranslocoModule,
    FormComponent,
  ],
})
export class SharedModule {}
