import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'tile',
  templateUrl: './tile.component.html',
  styleUrl: './tile.component.scss',
  host: { '(click)': 'onClick()' },
})
export class TileComponent {
  @Input() label!: string;
  @Input() icon?: string;
  @Input() link?: string;
  @Input() disabled?: boolean;

  constructor(private router: Router) {}

  protected onClick() {
    if (this.link && !this.disabled) {
      this.router.navigate([this.link]);
    }
  }
}
